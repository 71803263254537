// .MTableToolbar-title-9 {
//   > h6 {
//     color: var(--bota-light-grayish-blue) !important;
//   }
// }
// .MTableToolbar-root-5 {
//   background-color: var(--bs-bota-light-grayish-orange) !important;
//   color: white !important;
// }

.MuiPaper-root {
  background-color: var(--bs-bota-light-grayish-blue) !important;
}
