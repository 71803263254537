.right-view {
  display: none;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  background-color: white;
  z-index: 500;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 20px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

  .right-view-header {
    margin: -20px -20px 20px -20px;
    padding: 15px;
  }

  .right-view-body {
    height: 100%;
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
  }

  .right-view-footer {
    padding-top: 15px;
  }
}

.drop-shadow {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: -180deg;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-blur: blur(75px);
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-drop-shadow: ;

  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);

  background-image: linear-gradient(
    89deg,
    rgba(255, 118, 223, 0.2),
    rgba(144, 88, 255, 0.2) 51.3%,
    rgba(0, 205, 250, 0.2)
  );
  border-radius: 9999px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  height: 1500px;
  top: -1450px;
  position: absolute;
}

.whatsapp-preview__content {
  background-image: url(https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png);
  height: calc(100% - 8.125rem);
  overflow-y: auto;
}

.current-date {
  margin: 0.75rem auto auto auto;
  width: 6.625rem;
  height: 1.125rem;
  line-height: 1.125rem;
  background: hsl(0, 0%, 81.2%);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 0.375rem;
  font-size: 0.75rem;
  text-align: center;
}

.content-list-container {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
}

.contentList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.25rem;
}

.message-area {
  position: relative;
  width: 17rem;
  min-height: calc(100% - 25rem);
  background-color: hsl(0, 0%, 100%);
  border-radius: 0.375rem;
}

.headerTextLayout {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  font-weight: bold;
  padding: 0.5rem 0.75rem 0rem 0.75rem;
}

.imgContainer {
  padding: 0.25rem 0.25rem 0rem 0.25rem;
}

.imgContainer img {
  border-radius: 0.25rem;
  width: 100%;
  object-fit: cover;
  max-height: 27rem;
}

.textAreaLayout {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
}

.footerMessage {
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: rgba(0, 0, 0, 0.4);
  font-weight: 500;
  padding: 0rem 0.75rem 0.5rem 0.75rem;
  word-wrap: break-word;
}

.timestamp {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.3);
  float: right;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  line-height: 0.875rem;
}

.separator___o6bnf {
  border: 0.005rem solid hsl(0, 0%, 81.2%);
  margin: auto;
  width: 91%;
}

.buttonArea {
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
}

.actionTypeLink {
  display: flex;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.icon__callnumber,
.icon__extlink {
  height: 0.8rem;
  width: 0.8rem;
  margin-top: 0.3rem;
  color: hsl(0, 0%, 10.6%);
}

.cta-link {
  color: hsl(0, 0%, 10.6%);
  font-weight: 600;
  line-height: 1.25rem;
  font-size: 1.125rem;
  margin-left: 0.2rem;
}

.actionTypeCallNumber {
  display: flex;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.icon__callnumber,
.icon__extlink {
  height: 0.8rem;
  width: 0.8rem;
  margin-top: 0.3rem;
  color: hsl(0, 0%, 10.6%);
}

.callNumber {
  color: hsl(0, 0%, 10.6%);
  font-weight: 600;
  margin-left: 0.2rem;
  line-height: 1.25rem;
  font-size: 1.125rem;
}

.bubble-arrow {
  position: relative;
  margin-top: -2.5rem;
  margin-left: -0.85rem;
}

.bubble-arrow:after {
  content: "";
  position: absolute;
  border: 0 solid transparent;
  border-top: 9px solid hsl(0, 0%, 100%);
  border-radius: 0 1.25rem 0;
  width: 0.7rem;
  height: 1.875rem;
  transform: rotate(145deg);
}

.bubble-arrow-right {
  position: relative;
  margin-bottom: -2.4rem;
  margin-left: 0;
}

.bubble-arrow-right:after {
  content: "";
  position: absolute;
  right: -0.3rem;
  transform: rotate(20deg) scaleY(-1);
  bottom: 2.6rem;
  border: 0 solid transparent;
  border-top: 9px solid hsl(0, 0%, 100%);
  border-radius: 0 1.25rem 0;
  width: 0.7rem;
  height: 1.875rem;
}

.bottomSpace___4dpm9 {
  margin-top: 1.5rem;
}

.quick-reply-container {
  max-width: 17rem;
  margin-top: 2rem;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
}

.quick-reply-button-area {
  background-color: hsl(0, 0%, 100%);
  padding: 0.75rem 0.25rem;
  border-radius: 0.375rem;
  display: flex;
  justify-content: center;
  min-width: calc(50% - 0.25rem);
  flex-direction: row;
  flex-grow: 1;
}

.quickreply-link {
  color: hsl(0, 0%, 10.6%);
  font-weight: 600;
  line-height: 1.25rem;
  font-size: 1.125rem;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.buttonsContainer {
  border: 1px solid hsl(0, 0%, 81.2%);
  border-radius: 0.5rem;
  padding: 3rem 1.5rem 1.5rem 1.5rem;
  background: hsl(0, 0%, 96%);
}

.closeButton {
  background: inherit;
  border-color: transparent;
  color: hsl(0, 0%, 10.6%);
  padding: 0.75rem;
  position: relative;
  float: right;
  top: -2rem;
  right: -0.5rem;
  align-items: center;
  border: 0 solid transparent;
  border-color: hsl(0, 0%, 10.6%);
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px -2px hsla(0, 0%, 11%, 0.14),
    0 2px 4px -2px hsla(0, 0%, 11%, 0.1), 0 2px 4px -2px hsla(0, 0%, 11%, 0.1);
  display: flex;
  font-family: "Roboto-Medium, system-ui, -apple-system, sans-serif";
  font-size: 1rem;
  line-height: 1.5rem;
  max-width: 100%;
  padding: 0.5rem 1rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

.mediaInfoContainer {
  background-color: hsl(0, 0%, 96%);
  border: 1px solid hsl(0, 0%, 81.2%);
  min-height: 5rem;
  border-radius: 0.5rem;
  margin-bottom: 1.8rem;
  display: flex;
  align-items: center;
}

.mediaIconContainer {
  width: 3rem;
  height: 3rem;
  float: left;
  margin-left: 1rem;
  margin-right: 1rem;
}

.mediaNameContainer {
  float: left;
  width: calc(100% - 8.125rem);
  overflow: scroll;
}

.mediaActionContainer {
  float: right;
  margin-right: 1rem;
}

.mediaIcon {
  border-radius: 0.5rem;
}

.mediaName {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Inter-Regular, Arial, sans-serif, ui-sans-serif, system-ui, -apple-system";
  width: 100%;
}

.mediaSize {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Inter-Regular, Arial, sans-serif, ui-sans-serif, system-ui, -apple-system";
  color: hsl(0, 0%, 41.2%);
}

.mediaCloseBtn {
  align-items: center;
  background: inherit;
  border: 0 solid transparent;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: 0 4px 8px -2px hsla(0, 0%, 11%, 0.14),
    0 2px 4px -2px hsla(0, 0%, 11%, 0.1), 0 2px 4px -2px hsla(0, 0%, 11%, 0.1);
  color: hsl(0, 0%, 10.6%);
  display: flex;
  font-family: "Inter-Regular, Arial, sans-serif, ui-sans-serif, system-ui, -apple-system";
  font-size: 1rem;
  line-height: 1.5rem;
  max-width: 100%;
  padding: 0.75rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

